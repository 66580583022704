<template>
  <v-container fluid class="fill-height">
    <v-row fluid :class="{ 'fill-height': !centered }">
      <v-col
        :cols="fluid ? 12 : null"
        :sm="fluid ? null : 10"
        :md="fluid ? null : 8"
        :xl="fluid ? null : 6"
        :offset-sm="fluid ? null : 1"
        :offset-md="fluid ? null : 2"
        :offset-xl="fluid ? null : 3"
      >
        <v-card>
          <v-toolbar
            v-if="title"
            color="primary"
            dark
            flat
            dense
          >
            <v-row v-if="!searchActive">
              <div class="col">
                <div
                  class="d-inline-block"
                  style="position: absolute; left: 10px; top: 8px"
                >
                  <slot name="left" />
                </div>

                <div class="text-center">
                  <v-toolbar-title v-text="title" />
                </div>

                <div
                  v-if="hasSearch"
                  class="d-inline-block"
                  style="position: absolute; right: 10px; top: 8px;"
                >
                  <v-btn
                    color="accent"
                    fab
                    x-small
                    @click="changeSearchActiveState"
                  >
                    <v-icon>search</v-icon>
                  </v-btn>
                </div>
                <div
                  v-if="back"
                  class="d-inline-block"
                  style="position: absolute; right: 10px; top: 8px;"
                >
                  <v-btn
                    icon
                    small
                    @click="$router.push(back)"
                  >
                    <v-icon>close</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-row>
            <v-row v-else>
              <v-col>
                <v-text-field
                  v-if="hasSearch"
                  ref="searchText"
                  autofocus
                  full-width
                  :value="search"
                  :placeholder="'Recherche'"
                  prepend-icon="search"
                  hide-details
                  single-line
                  @input="$emit('update:search', $event)"
                >
                  <template v-slot:append-outer>
                    <v-btn
                      style="margin-top: -5px; margin-right: -6px"
                      color="accent"
                      fab
                      x-small
                      @click="changeSearchActiveState"
                    >
                      <v-icon>close</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <template v-if="$slots.extension" #extension>
              <slot name="extension" />
            </template>
          </v-toolbar>
          <v-card-text :class="{'pa-0' : noContentPadding}">
            <slot />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AgCardContainer',
  props: {
    title: {
      type: String,
      default: '',
    },
    centered: {
      type: Boolean,
      default: false,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: null,
    },
    noContentPadding: {
      type: Boolean,
      default: false,
    },
    back: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    searchActive: false,
  }),
  computed: {
    hasSearch () {
      return this.search !== null
    },
  },
  methods: {
    changeSearchActiveState () {
      this.$emit('update:search', '')
      this.searchActive = !this.searchActive
    },
  },
}
</script>
