<template>
  <ag-card-container
    :search.sync="search"
    title="Choix d'un ordre de fabrication"
    fluid
    no-content-padding
  >
    <batch-list
      :value="batchList"
      :loading="loadingData"
      :search="search"
    />

    <ag-float-button-container>
      <v-btn
        v-if="role.level > 2"
        to="create"
        append
        color="accent"
        fab
      >
        <v-icon>add</v-icon>
      </v-btn>
    </ag-float-button-container>

    <!-- RouterView for modals -->
    <router-view />
  </ag-card-container>
</template>

<script>
import AgCardContainer from 'src/components/common/AgCardContainer'
import AgFloatButtonContainer from 'src/components/common/AgFloatButtonContainer'
import { mapActions, mapGetters } from 'vuex'
import Repositories from 'src/repositories'
import BatchList from '../components/BatchList'

export default {
  name: 'BatchListContainer',
  components: {
    AgCardContainer,
    AgFloatButtonContainer,
    BatchList,
  },
  data: () => ({
    batchList: [],
    search: '',
    loadingData: false,
  }),
  computed: {
    ...mapGetters('me', {
      role: 'role',
      userPartsType: 'userPartsType',
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler ({ name }) {
        if (name === 'BatchList' || !this.batchList.length) {
          this.fetchData()
        }
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    async fetchData () {
      try {
        this.loadingData = true
        const result = await Repositories.batch.getList({ archived: false })
        this.batchList = result.filter(batch => this.userPartsType.includes(batch.partType._id))
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingData = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.float-chip {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 12px;
}
</style>
