<template>
  <v-data-table
    height="calc(100vh - 160px)"
    fixed-header
    :value="selected"
    :headers="headers"
    :items="items"
    :items-per-page="-1"
    hide-default-footer
    :search="search"
    :loading="loading"
    :loading-text="'Chargement en cours...'"
    item-key="_id"
    sort-by="number"
    sort-desc
    multi-sort
    @input="$emit('update:selected', $event)"
  >
    <template v-slot:[`item.createdAt`]="{ item }">
      <div>{{ moment(item.createdAt).format('L') }}</div>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn
        :to="`/batch/${item._id}/operation/${item.orderOperations[0]._id}`"
        append
        icon
      >
        <v-icon>check</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>

export default {
  name: 'BatchList',
  components: {},
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    selected: {
      type: Array,
      default: () => ([]),
    },
    search: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    headers () {
      const baseHeaders = [
        {
          text: 'OF',
          align: 'center',
          value: 'number',
        },
        {
          text: 'Date de création',
          align: 'center',
          value: 'createdAt',
        },
        {
          text: 'Référence',
          align: 'center',
          value: 'partNumber.name',
        },
        {
          text: 'Type de référence',
          align: 'center',
          value: 'partType.name',
        },
        {
          text: 'Quantité initial',
          align: 'center',
          value: 'quantity',
        },
        {
          text: 'Première pièce',
          align: 'center',
          value: 'firstPartSerialNumber',
        },

      ]

      if (this.light) {
        return baseHeaders
      }

      return [
        ...baseHeaders,
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          filterable: false,
          align: 'center',
          width: '15%',
        },
      ]
    },
    items () {
      return this.value
    },
  },
}
</script>
