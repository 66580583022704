<template>
  <div
    class="float-action-button"
    :class="{'right-float-action-button': !left, 'left-float-action-button': left}"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AgFloatButtonContainer',
  props: {
    left: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.right-float-action-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.left-float-action-button {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 99;
}

.float-action-button * {
  margin: 8px;
}
</style>
